<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedFormattingActions"
        :default-value="properties.action"
        step-label="formatting"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'DECIMAL_FORMAT'" cols="12">
      <v-autocomplete
        :key="`${step.id}-locale`"
        v-model="properties.fields.locale"
        :items="localesFormatting"
        :label="$lang.labels.locale"
        outlined
        dense
        required
        data-cy="formatting-locale"
        :readonly="!canEdit"
        class="required-asterisk"
        :rules="[v => !!v || $lang.labels.required]"
      />
      <v-text-field
        :key="`${step.id}-pattern`"
        v-model="properties.fields.pattern"
        outlined
        dense
        class="required-asterisk"
        data-cy="formatting-pattern"
        :label="$lang.labels.pattern"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
      />
      <v-text-field
        :key="`${step.id}-groupingSeparator`"
        v-model="properties.fields.groupingSeparator"
        outlined
        dense
        data-cy="formatting-groupingSeparator"
        :label="$lang.labels.groupingSeparator"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-decimalSeparator`"
        v-model="properties.fields.decimalSeparator"
        outlined
        dense
        data-cy="formatting-decimalSeparator"
        :label="$lang.labels.decimalSeparator"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col cols="12" class="pt-1 pb-2">
      <encoder-step-converts
        :key="`${step.id}-converts`"
        step-type="FORMATTING"
        type="converts"
        :can-edit="canEdit"
        :data="properties.converts"
        @dataChanged="handleChange('converts', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import EncoderStepConverts from '../EncoderStepConverts'
import { ActionSelect } from './components'
import StepMixin from './StepMixin'

import { FORMATTING_ACTIONS } from './constants'
import { localesFormatting } from '@/utils/constants'

export default {
  name: 'FormattingStep',
  components: {
    ActionSelect,
    EncoderStepConverts
  },
  mixins: [StepMixin],
  data() {
    return {
      localesFormatting
    }
  },
  computed: {
    formattedFormattingActions() {
      return FORMATTING_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) {
      this.properties.action = 'DECIMAL_FORMAT'
      if (!this.properties.fields) {
        this.properties.fields = {
          locale: '',
          pattern: ''
        }
      }
    }
  }
}
</script>
